import router from '@/router';
import http from '@/http';
import { getRoute } from "@/router/getRoute";
import { readFromServer } from "@/components/admin/content/editor/accessControlBindings";
import { entityTypePluralize } from './contentHelpers';
import {
  CONTENT_RECEIVED,
  COMMENTS_RECEIVED,
  COMMENTS_UPDATE_LIMIT,
  COMMENTS_UPDATE_OFFSET,
  CLEAR_COMMENTS,
  EDITING_COMMENT
} from './contentTypes';

const state = getDefaultState();

const actions = {
  async getComments({commit, state, rootState}) {
    try {
      if (rootState.content.isLoaded && !state.isLoaded) {
        if (rootState.user.accessPrivileges.indexOf('VIEW_COMMENTS') >= 0) {
          const entityTypePlural = entityTypePluralize(rootState.content.type);
          const entityId = rootState.content.id;
          const params = {
            limit: state.limit,
            offset: state.offset * state.limit
          };

          const { headers, data } = await http.get(`${entityTypePlural}/${entityId}/comments`, {params});
          const dataMap = await data.map(readFromServer)
          const comments = Array.from(dataMap.values())
          commit(COMMENTS_RECEIVED, { comments: comments, meta: headers });
        } else {
          const currentRoute = getRoute();
          const contentPath = currentRoute.path.substring(0, currentRoute.path.indexOf('comments'));

          router.replace({path: `${contentPath}info`});
        }
      }
    } catch (err) {
      commit(COMMENTS_RECEIVED, {comments: []});
    }
  },
  async saveComment({dispatch, rootState}, {comment}) {
    try {
      const entityTypePlural = entityTypePluralize(rootState.content.type);
      const entityId = rootState.content.id;

      const formData = new URLSearchParams();
      formData.append('comment', comment.text);
      formData.append('global', comment.acl === 'all');
      formData.append('publicBelowOrg', comment.acl === 'myOrgAndChildren');

      if (comment.id) {
        await http.put(`${entityTypePlural}/${entityId}/comments/${comment.id}`, formData);
      } else {
        await http.post(`${entityTypePlural}/${entityId}/comments`, formData);
      }
      dispatch('getComments');
    } catch (err) {
      // na
    }
  },
  async editingComment({commit}, comment) {
    await commit('EDITING_COMMENT', comment)
  },
  async removeComment({dispatch, rootState}, {comment}) {
    try {
      const entityTypePlural = entityTypePluralize(rootState.content.type);
      const entityId = rootState.content.id;

      await http.delete(`${entityTypePlural}/${entityId}/comments/${comment.id}`);
      dispatch('getComments');
    } catch (err) {
      // na
    }
  },
  setCommentsPage({commit, dispatch}, page) {
    commit(COMMENTS_UPDATE_OFFSET, {
      offset: Math.max(page, 0)
    });
    dispatch('getComments');
  },
  setCommentsResultsPerPage({commit, dispatch}, limit) {
    commit(COMMENTS_UPDATE_LIMIT, {limit});
    dispatch('getComments');
  }
};

const mutations = {
  [COMMENTS_RECEIVED](state, {comments, meta}) {
    state.items = comments;
    state.total = meta['x-count'] ? Number(meta['x-count']) : 0;
  },
  [CONTENT_RECEIVED](state, {content}) {
    state.items = [];
    state.offset = 0;
    state.limit = 20;
    state.total = content.commentCount || 0;
  },
  [COMMENTS_UPDATE_OFFSET](state, {offset}) {
    state.offset = offset;
  },
  [COMMENTS_UPDATE_LIMIT](state, {limit}) {
    state.limit = limit;
    state.offset = 0;
  },
  [CLEAR_COMMENTS] (state) {
    Object.assign(state, getDefaultState())
  },
  [EDITING_COMMENT] (state, payload) {
    state.editingComment = payload? payload.comment : null
  }
};

export default {
  state,
  actions,
  mutations
};

function getDefaultState() {
  return {
    items: [],
    offset: 0,
    limit: 20,
    total: 0,
    editingComment: null
  }
}
