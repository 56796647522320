export const CONTENT_RECEIVED = 'CONTENT_RECEIVED'
export const CONTENT_LOADING = 'CONTENT_LOADING'
export const CLEAR_CONTENT = 'CLEAR_CONTENT'

export const TOC_RECEIVED = 'TOC_RECEIVED'
export const TOC_LOADING = 'TOC_LOADING'
export const TOC_BOOK_RECIEVED = 'TOC_BOOK_RECIEVED'
export const TOC_UPDATE_OPEN = 'TOC_UPDATE_OPEN'
export const TOC_UPDATE_SEARCH = 'TOC_UPDATE_SEARCH'
export const TOC_RESIZE = 'TOC_RESIZE'
export const CLEAR_TOC = 'CLEAR_TOC'

export const BOM_COLUMNS_RECEIVED = 'BOM_COLUMNS_RECEIVED'
export const BOM_LOADING = 'BOM_LOADING'
export const BOM_ILLUSTRATION_RECEIVED = 'BOM_ILLUSTRATION_RECEIVED'
export const BOM_ITEMS_SELECT = 'BOM_ITEMS_SELECT'
export const BOM_RECEIVED = 'BOM_RECEIVED'
export const BOM_UPDATE_LAYOUT = 'BOM_UPDATE_LAYOUT'
export const CLEAR_BOM = 'CLEAR_BOM'
export const CLEAR_BOM_COLUMNS = 'CLEAR_BOM_COLUMNS'
export const THREE_D_MODAL_CLOSE = 'THREE_D_MODAL_CLOSE'
export const THREE_D_MODAL_OPEN = 'THREE_D_MODAL_OPEN'

export const PRICING_RECEIVED = 'PRICING_RECEIVED'
export const PRICING_LOADING = 'PRICING_LOADING'
export const CLEAR_PRICING = 'CLEAR_PRICING'

export const TAGS_RECEIVED = 'TAGS_RECEIVED'
export const TAGS_LOADING = 'TAGS_LOADING'
export const CLEAR_TAGS = 'CLEAR_TAGS'

export const COMMENTS_RECEIVED = 'COMMENTS_RECEIVED'
export const COMMENTS_UPDATE_OFFSET = 'COMMENTS_UPDATE_OFFSET'
export const COMMENTS_UPDATE_LIMIT = 'COMMENTS_UPDATE_LIMIT'
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS'
export const EDITING_COMMENT = 'EDITING_COMMENT'

export const RELATED_RECEIVED = 'RELATED_RECEIVED'
export const RELATED_UPDATE_OFFSET = 'RELATED_UPDATE_OFFSET'
export const RELATED_UPDATE_LIMIT = 'RELATED_UPDATE_LIMIT'
export const CLEAR_RELATED = 'CLEAR_RELATED'

export const WHEREUSED_RECEIVED = 'WHEREUSED_RECEIVED'
export const WHEREUSED_UPDATE_OFFSET = 'WHEREUSED_UPDATE_OFFSET'
export const WHEREUSED_UPDATE_LIMIT = 'WHEREUSED_UPDATE_LIMIT'
export const WHEREUSED_COUNT = 'WHEREUSED_COUNT'
export const CLEAR_WHEREUSED = 'CLEAR_WHEREUSED'

export const SUGGESTIONS_RECEIVED = 'SUGGESTIONS_RECEIVED'
export const SUGGESTIONS_UPDATE_OFFSET = 'SUGGESTIONS_UPDATE_OFFSET'
export const SUGGESTIONS_UPDATE_LIMIT = 'SUGGESTIONS_UPDATE_LIMIT'
export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS'
export const VIEWER_CURRENT_PAGE = 'VIEWER_CURRENT_PAGE'

export const clearContentMutations = [ CLEAR_CONTENT, CLEAR_BOM, CLEAR_BOM_COLUMNS, CLEAR_COMMENTS, CLEAR_PRICING,
  CLEAR_RELATED, CLEAR_SUGGESTIONS, CLEAR_TAGS, CLEAR_TOC, CLEAR_WHEREUSED ]
