<template>
  <comments-item :comment="source"
                 :username="username"
                 :editing="editingComment?.id === source.id"
                 :can-add-and-edit-comments="canAddAndEditComments"
                 :can-edit-all-comments="canEditAllComments"
                 @edit="edit"
                 @remove="removeComment">
    <comments-edit v-if="editingComment?.id === source.id"
                   :comment="source"
                   @submit="submit"
                   @cancel="cancel"/>
  </comments-item>
  <hr>
</template>

<script setup lang="ts">
import CommentsItem from "./CommentsItem.vue"
import CommentsEdit from "./CommentsEdit.vue"
import { computed , ref, defineEmits } from "vue"
import { useStore } from "vuex"

const store = useStore()
const editing = ref<Object>({
  acl: 'all',
  id: '',
  text: '',
  publicBelowOrg: null
})

const props = defineProps({
  source: {
    type: Object,
    default () {
      return {}
    }
  },
  index: {
    type: Number
  },
})

const emit = defineEmits(['editing'])
const username = computed(() => store.state.user.username)
const canAddAndEditComments = computed(() => store.getters['user/canAddAndEditComments'])
const canEditAllComments = computed(() => store.getters['user/canEditAllComments'])
const editingComment = computed(() => store.state.content.comments.editingComment)

const removeComment = async (val: any) => {
  await store.dispatch('content/removeComment', { comment: val })
}
const clearEditingComment = async () => {
  await store.dispatch('content/editingComment', null)
}
const cancel = async () => {
  editing.value = {
    id: '',
    text: '',
    acl: 'all'
  }
  await clearEditingComment()
}
const edit = async (comment: any) => {
  await clearEditingComment()
  editing.value = JSON.parse(JSON.stringify(comment))
  await store.dispatch('content/editingComment', { comment: props.source })
}
const submit = async (value: any) => {
  await store.dispatch('content/saveComment', { comment: props.source })
  await clearEditingComment()
}

</script>

<style scoped>

</style>
