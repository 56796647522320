<template>
  <section class="comments-container">
    <section v-if="comments.length"
             class="comments-results"
             :class="{'full-height': !!editingComment, 'new-active': active}">
      <virtual-list :data-component="CommentsList"
                    :data-sources="comments"
                    :keeps="5"
                    :data-key="'id'">
      </virtual-list>
    </section>
    <div v-if="!total"
         class="no-data">
      <h1 data-int="comments-no-data"
          class="subtitle has-text-centered">
        {{ $t('assetsNotFound', {type: $tc('comment', 2)}) }}
      </h1>
    </div>
    <div v-if="canAddAndEditComments && !editingComment"
         class="comments-new"
         :class="{'active': active}">
      <comments-edit :comment="editing"
                     @active="val => active = val"
                     @submit="submit"
                     @cancel="cancel"/>
    </div>
  </section>
</template>

<script setup lang="ts">
import CommentsEdit from './CommentsEdit.vue'
import CommentsList from "./CommentsList.vue"
import VirtualList from "vue3-virtual-scroll-list"
import { computed, onMounted, ref } from "vue"
import { useStore } from "vuex"

const store = useStore()
const editing = ref<Object>({
  acl: 'all',
  id: '',
  text: '',
  publicBelowOrg: null
})
const loading = ref<boolean>(false)
const active = ref<boolean>(false)
const username = computed(() => store.state.user.username)
const comments = computed(() => store.state.content.comments.items)
const editingComment = computed(() => store.state.content.comments.editingComment)
const total = computed(() => store.state.content.comments.total)
const canAddAndEditComments = computed(() => store.getters['user/canAddAndEditComments'])

const getComments  = async () => {
  await store.dispatch('content/getComments')
}
const submit = async () => {
  await store.dispatch('content/saveComment', { comment: editing.value })
}
// cancel is only being used for click button event
const cancel = () => {
  editing.value = {
    id: '',
    text: '',
    acl: 'all'
  }
}

onMounted(async () => {
  await getComments()
})

</script>

<style scoped lang="scss">
.comments-container {
  flex: 1;
  display: flex;
  flex-flow: column;

  .comments-new {
    height: 150px;
    &.active {
      height: 250px;
    }
  }
  @media screen and (max-width: 768px), print {
    .comments-new {
      padding: 0 0 1em 1em;
    }
  }
}
.comments-results {
  flex: 1;
  overflow: auto;
  padding: 0 1em 1em;
  border-bottom: lightgray 1px solid;
  max-height: 360px;
  &.new-active {
    max-height: 260px;
  }
  &.full-height {
    max-height: 100%;
  }
}
@media screen and (max-width: 768px), print {
  .comments-results {
    padding: 0 1rem .5rem;
  }
}
@media screen and (max-width: 1060px), print {
  .comments-results {
    padding: 0 1rem .5rem;
  }
}
.no-data {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
</style>
